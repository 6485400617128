import { ReactNode, useState } from 'react'
import classnames from 'classnames'
import { CharStatus } from '../../lib/statuses'
import { MAX_WORD_LENGTH, REVEAL_TIME_MS } from '../../constants/settings'
import { getStoredIsHighContrastMode } from '../../lib/localStorage'

import Spinner from 'react-bootstrap/Spinner'

type Props = {
  children?: ReactNode
  value: string
  width?: number
  status?: CharStatus
  onClick: (value: string) => void
  isRevealing?: boolean
  Disable?: any
}

export const Key = ({
  children,
  status,
  width = 36,
  value,
  Disable = false,
  onClick,
  isRevealing,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false) // State for spinner

  const keyDelayMs = REVEAL_TIME_MS * MAX_WORD_LENGTH
  const isHighContrast = getStoredIsHighContrastMode()

  const classes = classnames(
    'keyboard-button flex items-center justify-center rounded mx-0.5 cursor-pointer select-none dark:text-white',
    {
      'transition ease-in-out': isRevealing,
      'bg-slate-200 dark:bg-slate-600 active:bg-slate-400': !status,
      'bg-slate-400 dark:bg-slate-800 text-white': status === 'absent',
      'bg-orange-500 hover:bg-orange-600 active:bg-orange-700 text-white':
        status === 'correct' && isHighContrast,
      'bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700 text-white':
        status === 'present' && isHighContrast,
      'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white':
        status === 'correct' && !isHighContrast,
      'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white':
        status === 'present' && !isHighContrast,
    }
  )

  const styles = {
    transitionDelay: isRevealing ? `${keyDelayMs}ms` : 'unset',
    width: `${width}px`,
    height: '46px',
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (value === 'ENTER') {
      setIsLoading(true)

      
      setTimeout(() => {
        setIsLoading(false)  
      }, keyDelayMs)
    }

    onClick(value)
    event.currentTarget.blur()
  }

  if (value === 'ENTER') {
    return (
      <button
        style={styles}
        disabled={isLoading} // Disable the button while the spinner is active
        className={`${classes} ${Disable ? 'disableBtn' : ''}`}
        onClick={handleClick}
      >
        {isLoading ? (
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        ) : (
          children || value
        )}
      </button>
    )
  } else {
    return (
      <button style={styles} className={classes} onClick={handleClick}>
        {children || value}
      </button>
    )
  }
}
