

export default function SuggestionPopup()
{
    return(
        
        <div class="suggetion">
            <div class="suggetion-inner">
            Guess a word and hit enter
            </div>
            <div class="suggetion-shape">
            <img src="/Polygon 1.png" alt=""/>
            </div>
        </div>
    )
}