

import { BaseModal } from './BaseModal';
import { GuessRows } from './GuessRows';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  guesses: string[];  // Ensure the required props are passed
  currentGuess: string;
  isRevealing: boolean;
  currentRowClassName: string;
};

export const GuessModel = ({
  isOpen,
  handleClose,
  guesses,
  currentGuess,
  isRevealing,
  currentRowClassName,
}: Props) => {
  return (
    <BaseModal title="Guess History" isOpen={isOpen} handleClose={handleClose}>
        <div style={{height:'20px'}}></div>
      <GuessRows
        guesses={guesses}
        currentGuess={currentGuess}
        // isRevealing={isRevealing}
        currentRowClassName={currentRowClassName}
      />
    </BaseModal>
  );
};
