import { useState } from "react";
import PreStatsModel from "../modals/PreStatsModel";

export default function TopHead({ setIsGuessModelOpen, setIsInfoModalOpen, headerTitle }) {
  const [isPreStatModelOpen, setIsPreStatModelOpen] = useState(false);

  const toggleModalState = () => {
    setIsPreStatModelOpen(prevState => !prevState);
  };

  return (
    <>
      <div className="top-head-outline">
        <div className="top-head">
          <div className="top-brand-name">
           <img src="/headerlogo.png" /> News Word <span className="top-client-name" > {headerTitle}</span>
          </div>
          <div>
            <img
              onClick={toggleModalState}
              className="how-to-play-icon"
              src="/union.png"
              alt="How to play"
            />
          </div>
        </div>
      </div>

      {isPreStatModelOpen && (
        <PreStatsModel
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsGuessModelOpen={setIsGuessModelOpen}
          isOpen={isPreStatModelOpen}
          handleClose={toggleModalState}
        />
      )}
    </>
  );
}
