import {
  InformationCircleIcon,
  ChartBarIcon,
  CogIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'

import './App.css'

function Error() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState('d')

  if (isSuccess == true) {
    return <div></div>
  } else {
    return (
      <div>
        <section className="page_404">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 ">
                <img
                  src={`${process.env.PUBLIC_URL}/930-9306658_404-not-found.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Error
