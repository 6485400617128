import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  clientName: any
}

export const InfoModal = ({ isOpen, handleClose , clientName  }: Props) => {
  return (
    <BaseModal title="How To Play" isOpen={isOpen} handleClose={handleClose}>
      <p  style={{lineHeight:'19px',}}>
      Guess the word connected to news from <span style={{ textTransform:'capitalize'}}> {clientName} </span>in 6 tries.
      </p>

      <p style={{fontSize:'16px', fontWeight:'bold'}} >Example</p>

      <div className="flex">
        <Cell value="T" status="correct" />
        <Cell value="H" status="present" />
        <Cell value="I" status="absent" />
        <Cell value="N" status="absent" />
        <Cell value="K" status="absent" />
      </div>
      <ul style={{listStyle:'disc', marginTop:'15px' , lineHeight:'19px'}}>
        <li>T is in the word and in the correct place</li>
        <li>H is in the word but in the wrong place</li>
        <li>I, N, K are not in the word</li>
      </ul>
      <div className='border_bottom'></div> 
      <p  style={{lineHeight:'19px'}}>A new puzzle is released daily at midnight.</p>
      <div className='got_it_btn' >
      <button onClick={handleClose} type='button'>Got it</button>
      </div>

      {/* <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        This is an open source version of the word guessing game we all know and
        love -{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
          check out the code here
        </a>{' '}
      </p> */}
    </BaseModal>
  )
}
