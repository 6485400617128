import { log } from 'console';
import React, { useRef, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


type SponsorshipmoduleProps = {
    sponsorshipLogo: any,
    sponsorshipText: any,
    sponsorshipHyperlink: any,
};
export default function Sponsorshipmodule({
    sponsorshipLogo,
    sponsorshipText,
    sponsorshipHyperlink,
}: SponsorshipmoduleProps) {

    const baseURL = "https://newsword.ai/member-area/public/";
    const imgPath = `${baseURL}${sponsorshipLogo}`;

    const elementRef = useRef<HTMLDivElement | null>(null);


    return (

         <div className='sponsorship-module' style={{ borderTop: 'none' }}>
             <div className='sponsorship-module-inner mx-width'>
                 <a href={sponsorshipHyperlink} target="_blank" rel="noopener noreferrer" className='hyperlink'>
                     <span style={{ width: '100%', maxWidth: '40px', }}><img className='sponsorship-module-logo' src={imgPath} alt="" /></span>
                     <span className='sponsorship-text'>{sponsorshipText}</span>
                 </a>
             </div>
         </div>
       



    );
}
