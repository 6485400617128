import Countdown from 'react-countdown';
import { StatBar } from '../stats/StatBar';
import { Histogram } from '../stats/Histogram';
import { GameStats } from '../../lib/localStorage';
import { shareStatus } from '../../lib/share';
import { tomorrow } from '../../lib/words';

import Moment from 'moment-timezone';

import {
  STATISTICS_TITLE,
  RELATED_INFO,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
  COPY_TO_CLIPBOARD,
} from '../../constants/strings';
import { useSearchParams } from 'react-router-dom';
import { log } from 'console';
import { useState } from 'react';
import { BaseModal } from '../modals/BaseModal';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  guesses: string[];
  gameStats: GameStats;
  isGameLost: boolean;
  isGameWon: boolean;
  handleShare: () => void;
  isHardMode: boolean;
  Statistics: boolean;
  wordData: any;
  showTimer: any;
  date: any;
  puzzleHeadline: any;
  articleUrl: any;
  rootUrl:any;
  dateOfArticle:any;
  articleDescription:any;
  headerTitle : any;
  signupModuleToggler? : any;
  signupbuttonURL? : any;
  signupModelDescription? : any;
  
};

const Result = ({
  rootUrl,
  dateOfArticle,
  articleDescription,
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShare,
  isHardMode,
  Statistics,
  wordData,
  date,
  showTimer,
  puzzleHeadline,
  articleUrl,
  headerTitle,
  signupModuleToggler,
  signupbuttonURL,
  signupModelDescription,
  
}: Props) => {

  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('embed');
  const [shareButtonText, setShareButtonText] = useState(SHARE_TEXT);
  const [iframeModel, setIframeModel] = useState(true);
  const [iframeModule, setIframeModule] = useState(false);
  const checkClickEvent = () =>{
    setShareButtonText(COPY_TO_CLIPBOARD);
  }
  
  return (
    <div>
     
      <h4 className=" result-heading leading-6  text-gray-900 dark:text-gray-100" style={{marginBottom: '1rem',}}>
        {RELATED_INFO}
      </h4>
      <div className="puzzle-headline p-two">  
      <h6 className='root-url'>
        {/* <a href={rootUrl} target="_blank" rel="noopener noreferrer"  >{splitUrl}</a> */}
        {rootUrl}
      </h6>  
        <p className='article-headline' dangerouslySetInnerHTML={{ __html: puzzleHeadline }} />
        <p className='gray-text' style={{color:'rgba(98, 98, 98, 1)',fontWeight:'400' }}>{articleDescription}</p>
        <p style={{color:'#626262', fontSize:'16px', marginBottom:'30px'}}>{dateOfArticle}</p>
        <a className='read-article'  href={articleUrl} target="_blank" rel="noopener noreferrer" >Read Article</a>
     
      </div>
    {/*  */}
    {(isGameLost || isGameWon) && (
        <div>
          <div className="w-ful  dark:text-white" style={{marginTop:'15px' }}>
            <button
              style={{ fontSize: '16px!important' }}
              type="button"
              className="share-btn mt-2  w-full rounded-full  px-4 py-2  text-base font-medium  hover:bg-indigo-700  sm:text-sm"
              onClick={() => {
                shareStatus(
                  guesses,
                  isGameLost,
                  isHardMode,
                  headerTitle,
                  date,
                  wordData.shareable_link
                );
                handleShare();
                checkClickEvent();
              }}
            >
              {shareButtonText}
            </button>
          </div>
          {/* <div className="bottom-counter">
            <h6>{NEW_WORD_TEXT}</h6>
            <Countdown
              className="result-heading  text-gray-900 dark:text-gray-100"
              date={tomorrow}
              daysInHours={true}
            />
          </div> */}
        </div>
      )}
    {/*  */}
      <div className={paramValue === 'true' || paramValue == 'true' ? 'is-embed' : ''}><h4 className=" result-heading leading-6  text-gray-900 dark:text-gray-100">
        {STATISTICS_TITLE}
      </h4>
      <StatBar gameStats={gameStats} />
      </div>
     
      
     
      <h4 className="result-heading leading-6  text-gray-900 dark:text-gray-100">
        {GUESS_DISTRIBUTION_TEXT}
      </h4>

      <Histogram gameStats={gameStats}
        isGameWon={isGameWon}
        paramValue={paramValue}
        headerTitle= {headerTitle}
        signupModuleToggler = {signupModuleToggler}
        signupModelDescription = {signupModelDescription}
        setIframeModule = {setIframeModule}
         />
        <BaseModal title="" isOpen={iframeModule} handleClose={()=>setIframeModule(false)} iframeModel={iframeModel}>
              <iframe
                src={signupbuttonURL}
                title="Example Iframe"
                width="100%"
                height="400px"
              ></iframe>
        </BaseModal>

      
    </div>
  );
};

export default Result;
