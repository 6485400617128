import React, { useRef, useEffect } from 'react';

type PreStatsModelProps = {
  setIsInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setIsGuessModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
};

export default function PreStatsModel({ setIsGuessModelOpen, setIsInfoModalOpen, isOpen, handleClose }: PreStatsModelProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  const updateModalState = () => {
    setIsInfoModalOpen(true);
  };

  const updateGuessModel = () => {
    setIsGuessModelOpen(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null; 

  return (
    <div className="prestatmodel">
      <div ref={modalRef} className="modal-content">
        <div>
          <div className="guess_histroy" onClick={updateGuessModel}>
            Guess History
          </div>
          <div className="guess_histroy" onClick={updateModalState}>
            How To Play
          </div>
        </div>
        <div style={{marginTop: '37px'}}>
          <div className="prestat-bottom-text" style={{ display: 'flex', justifyContent: 'start' }}>
            <div>
              © 2024 &nbsp;
              <a href="https://newsgames.org" style={{ color: '#a7a7a7', textDecoration: 'underline' }}>
                News Games
              </a>
              &nbsp;<b>.</b>&nbsp;
              <a href="https://newsgames.org/terms" style={{ color: '#a7a7a7', textDecoration: 'underline' }}>
                Terms
              </a>
              &nbsp;<b>.</b>&nbsp;
              <a href="https://newsgames.org/privacy" style={{ color: '#a7a7a7', textDecoration: 'underline' }}>
                Privacy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
