import { log } from "console"
import { useState } from "react"

type Props = {
  index: number
  size: number
  label: string
  isGameWon: boolean
}

export const Progress = ({ index, size, label, isGameWon }: Props) => {

  const checkPercentage = (
    size > 14 ? '' : 'pg-br'
  );
  const value = localStorage.getItem('gameState');
  const parsedValue = value ? JSON.parse(value) : null;
  const triesCount = parsedValue?.guesses?.length - 1;

  let checkWon = 'bg-slate-400';

  if (isGameWon == true && index == triesCount) {
    checkWon = ('bg-green-500');
  }

  return (
    <div className="flex justify-left m-1"
      style={{ display: 'flex', flexDirection: 'column-reverse', alignItems: 'center' }}
    >
      <div className="items-center justify-center w-2" style={{ fontWeight: 'bold' }}>{index + 1}</div>
      <div className="rounded-full" style={{ display: 'flex', flexDirection: "column-reverse" }}>
        <div
          style={{ height: `${5 + size}px` }}
          className={`pg-br-w-h ${checkWon}  text-xs font-medium text-blue-100 
          text-center p-0.5 ${checkPercentage}  `}
        // rounded-l-full for round corners
        >
          {label}
        </div>
      </div>
    </div>
  )
}
