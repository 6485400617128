import { WORDS } from '../constants/wordlist'
import { VALID_GUESSES } from '../constants/validGuesses'
import { WRONG_SPOT_MESSAGE, NOT_CONTAINED_MESSAGE } from '../constants/strings'
import { getGuessStatuses } from './statuses'
import { useParams } from 'react-router-dom'
// var moment = require('moment-timezone');
import moment from 'moment-timezone'
export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALID_GUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
export const findFirstUnusedReveal = (word: string, guesses: string[]) => {
  const knownLetterSet = new Set<string>()
  for (const guess of guesses) {
    const statuses = getGuessStatuses(guess)

    for (let i = 0; i < guess.length; i++) {
      if (statuses[i] === 'correct' || statuses[i] === 'present') {
        knownLetterSet.add(guess[i])
      }
      if (statuses[i] === 'correct' && word[i] !== guess[i]) {
        return WRONG_SPOT_MESSAGE(guess[i], i + 1)
      }
    }
  }

  for (const letter of Array.from(knownLetterSet.values())) {
    // fail fast, always return first failed letter if applicable
    if (!word.includes(letter)) {
      return NOT_CONTAINED_MESSAGE(letter)
    }
  }
  return false
}
var words = ''
let solutionIndex: number
let solution = ''
let tomorrow: any
// let timeZone: any

export let getWordOfDay = (word: string) => {
  words = word
  moment.tz.setDefault('America/New_York')
  let getDateTime = new Date().toLocaleString('en-US', {
    timeZone: 'America/New_York',
  })
  let now = new Date(getDateTime).valueOf()
  let epochMs = new Date('January 1, 2022 00:00:00').valueOf()

  // let now = Date.now()
  let msInDay = 86400000
  let index = Math.floor((now - epochMs) / msInDay)
  let nextday = (index + 1) * msInDay + epochMs
  let text = words || ''

  // console.log('nextday=>', new Date(now))

  solution = text.toUpperCase()
  solutionIndex = index
  tomorrow = nextday
}
export { solution, solutionIndex, tomorrow }
// export let { solution, solutionIndex, tomorrow } = getWordOfDay(words)
