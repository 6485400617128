import { useEffect, useState, useRef } from 'react';
import { MAX_CHALLENGES } from '../../constants/settings'
import { CompletedRow } from '../grid/CompletedRow';
import { PreviousRow } from '../grid/PreviousRow';
import SuggestionPopup from '../../inc/SuggestionPopup';
import { CurrentRow } from '../grid/CurrentRow';
import { EmptyRow } from '../grid/EmptyRow';
// import { CompletedRow } from '../../CompletedRow'
// import { CurrentRow } from './CurrentRow'
// import { EmptyRow } from './EmptyRow'
// import SuggestionPopup from '../../inc/SuggestionPopup';
// import { PreviousRow } from './PreviousRow';

type Props = {
    guesses: string[]
    currentGuess: string
    isRevealing?: boolean
    currentRowClassName: string
}

export const GuessRows = ({
    guesses,
    currentGuess,
    isRevealing,
    currentRowClassName,

}: Props) => {

    var marginTopGiven = '';
    const checkWidth = () => {
        let initalWidht = 0;
        let percentage = 50;
        if (window.innerHeight > 264) {
            if (window.innerHeight > 570) {
                percentage = 50;
            } else if (window.innerHeight > 500) {
                percentage = 46;
            } else if (window.innerHeight > 460) {
                percentage = 42;
            } else if (window.innerHeight > 450) {
                percentage = 40;
            } else if (window.innerHeight > 400) {
                percentage = 38;
            } else if (window.innerHeight <= 400) {
                percentage = 35;
            }

            if (window.innerHeight > 800) {
                marginTopGiven = '15%';
            } else if (window.innerHeight > 780) {
                marginTopGiven = '10%';
            } else if (window.innerHeight > 730) {
                marginTopGiven = '8%';
            } else if (window.innerHeight > 700) {
                marginTopGiven = '5%';
            } else if (window.innerHeight > 650) {
                marginTopGiven = '3%';
            } else if (window.innerHeight > 550) {
                marginTopGiven = '';
            } else if (window.innerHeight > 480) {
                marginTopGiven = '-1%';
            } else {
                marginTopGiven = '-1%';
            }
            // initalWidht = window.innerHeight  - 264;
            initalWidht = window.innerHeight * percentage / 100;
        }
        // console.log('initalWidht'+percentage);
        return initalWidht;
    }
    const checkHeight = () => {


        let initalHeight = 0;
        if (window.innerHeight > 263) {
            // initalHeight = window.innerHeight - 263;
            initalHeight = window.innerHeight * 65 / 100;
        }
        return initalHeight;
    }
    const [width, setWidth] = useState(checkWidth());
    const [height, setHeight] = useState(checkHeight());

    const isMobile = window.innerWidth <= 768;
    const updateDimensions = () => {
       
        setWidth(checkWidth());
        setHeight(checkHeight());
      
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const empties =
        guesses.length < MAX_CHALLENGES
            ? Array.from(Array(MAX_CHALLENGES - guesses.length))
            : []

    const dynamicIndex = guesses.length > 1 ? '' : '';
    const dynamicHeight = guesses.length > 0 ? '' : '359px';
    // console.log('data', currentRowClassName);
    
    return (

        <div className='box-container current-guess-row-new ' >
            
            {guesses.map((guess, i) => (

                <div key={i}  style={{marginBottom:'5px' , width:'100%', }}>
                <CompletedRow
                    key={i}
                    guess={guess}
                    isRevealing={isRevealing && guesses.length - 1 === i}
                   
                />
                </div>
            ))}

            <div className='' style={{ width: '100%' }}>

                {/* pb-6 */}
                <div className="row-margin-remove-new" style={{height : dynamicHeight}}>


                    {guesses.length > 0 ? (
                        <div >
                            {empties.map((_, i) => (
                                <EmptyRow key={i} />
                            ))}
                    
                        </div>

                    ) : <div className='preguess-text' >Your guesses will appear here.</div>}

                </div>
            </div>
        </div>

    )
}
