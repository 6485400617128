
export default function PuzzleNotFound()
{
    return(
        <div className="flex justify-center">
        <div className="no-puzzle" >
            <div className="get-started-logo" >
                <img
                    src="/Think.png"
                />
            </div>
            <div>
                <h5>No word today. See you tomorrow!</h5>
            </div>
        </div>
        </div>
    )
}