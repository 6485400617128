import React from 'react'
import ReactDOM from 'react-dom'
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css'
import App from './App'
import HomePageComponent from './HomePageComponent'
import Error from './error'
import reportWebVitals from './reportWebVitals'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { ReactGaKey } from './constants/settings'
import ReactGA from 'react-ga'
import 'bootstrap/dist/css/bootstrap.min.css';
import ResultPage from './components/result/ResultPage'


ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path={`/:company`} element={<App />}></Route>
      <Route path={`/`} element={<HomePageComponent />}></Route>
      <Route path={`/:company/result`} element={<ResultPage/>}></Route>
      <Route path="*" element={<Error />}></Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
