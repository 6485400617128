

import { useState } from 'react';
import { BaseModal } from './BaseModal';

type Props = {
    isOpen: boolean;
    handleClose: () => void;
    headerTitle: any;
    signupModelHeading ?:any;
    signupModelDescription? :any;
    signupButtonText? :any;
    signupbuttonURL? :any;
    maybeLaterButtonText ?:any;
    noThanksButtonText ?:any;

};

export const SignupModel = ({
    isOpen,
    handleClose,
    headerTitle,
    signupModelHeading ,
    signupModelDescription ,
    signupButtonText ,
    signupbuttonURL ,
    maybeLaterButtonText ,
    noThanksButtonText ,
}: Props) => {
    const [signupModel, setSignupModel] = useState(true);
    const [iframe, setIframe] = useState(false);
    const [iframeModel, setIframeModel] = useState(true);
    const onclicklater = () => {
        localStorage.setItem('ModelStatus', '0');
        handleClose();
    }
    const notshowagain = (message?: any) => {
        localStorage.setItem('ModelStatus', '1');
        if (message === 'signup') {
            setIframe(true);
        } else {
            handleClose();
        }

    }
    if (iframe) {
        return (
            <BaseModal title="" isOpen={isOpen} handleClose={handleClose} iframeModel={iframeModel}>
                {/* <div style={{ height: '20px' }}></div> */}
                <iframe
                    src={signupbuttonURL}
                    title="Example Iframe"
                    width="100%"
                    height="400px"
                ></iframe>
            </BaseModal>  
              
        );
    } else {
        return (
            <BaseModal title={signupModelHeading} isOpen={isOpen} handleClose={handleClose} signupModel={signupModel}>
                <div style={{ height: '5px' }}></div>
                <div className='model-description' >{signupModelDescription}</div>
                <div>
                    <a className='signup-itsfree'  onClick={() => notshowagain('signup')} >{signupButtonText}</a>
                    <a className='Maybe-later signup-itsfree'  onClick={onclicklater} >{maybeLaterButtonText}</a>
                    <a className='No-thanks signup-itsfree'  onClick={notshowagain} >{noThanksButtonText}</a>
                </div>
                <div className='model-bottom-text' >By signing up, you agree to the <a href="https://newsgames.org/terms" target="_blank" rel="noopener noreferrer" style={{ color: '#a7a7a7', textDecoration: 'underline', outline: 'none' }}>
                    Terms
                </a> and <a href="https://newsgames.org/privacy" target="_blank" rel="noopener noreferrer" style={{ color: '#a7a7a7', textDecoration: 'underline', outline: 'none' }}>
                        Privacy Policy
                    </a>.</div>
            </BaseModal>
        );
    }

};
