import React, { useState } from "react";

export default function StartPage({ paramValue , sponsorshipModule,  headerTitle, windowHeight, wordData, clientName , setIspaly ,setIsInfoModalOpen }) {


  function setWelcomePage(){
    setIspaly(true);
    if (localStorage.getItem('isReload') == 'done') {
      if (!localStorage.getItem('inModel')) {
        setIsInfoModalOpen(true);
        localStorage.setItem('inModel', 'done')
      }

      localStorage.setItem('puzzle_id', btoa(wordData.id))
    }
   
  }
  let divideHeight = '';
  if(sponsorshipModule ==  true && (paramValue === 'null' || paramValue == 'null' || !paramValue)){
    divideHeight = 120;
  }else{
    divideHeight = 59.5;
  }
  return (
    <div>
    <div className="startup_page_main mx-width" style={{ height: `${windowHeight - divideHeight}px` }} >
      <div className="welcome">
        <div className="flex justify-center" >
        <img style={{ height: "80px" }} src="/wave.png" />
        </div>
        <div className="welcome-text">Welcome!</div>
      </div>
      <div className="welcome-heading">
        <div className="welcome-heading-text">
         <p> You have 6 tries to guess a word connected to news from</p>
          </div>
        <div className="customer-name">
          <p>{clientName}.</p>

          </div>
      </div>
      <div className="spacer"></div>
      <div className="play-button">
        <button onClick={setWelcomePage} className="btn btn-dark play-btn" id="showModel2">
          Play
        </button>
      </div>
    </div>
    </div>
  );
}
